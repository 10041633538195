<template>
  <div class="qrcode-management">
    <div class="qrcode-management-header">
      <v-spacer />
      <v-btn color="primary" @click="save" :loading="isBusy"> {{ $t("GlobalUI.Save") }} </v-btn>
    </div>
    <div v-if="form" class="qrcode-management-grid">
      <div class="qrcode-management-item qrcode-management-item--general">
        <v-card class="qrcode-general-card" elevation="2">
          <v-toolbar flat>
            <v-toolbar-title> {{ $t("GlobalUI.General") }} </v-toolbar-title>
          </v-toolbar>

          <v-divider></v-divider>

          <v-card-text>
            <v-qrcode-wifi
              v-if="form.codeType === 'WIFI'"
              :title="form.title"
              :ssid="form.content.WIFI.ssid"
              :password="form.content.WIFI.password"
              :encryption="form.content.WIFI.encryption"
              @change="({ field, value }) => (form[field] = value)"
              @change:ssid="(value) => (form.content.WIFI.ssid = value)"
              @change:password="(value) => (form.content.WIFI.password = value)"
              @change:encryption="(value) => (form.content.WIFI.encryption = value)"
              :is-disabled="isDisabled"
            />
            <v-qrcode-supplier
              v-if="form.codeType === 'SUPPLIER'"
              :title="form.title"
              :subdomain="form.content.SUPPLIER.subdomain"
              @change="({ field, value }) => (form[field] = value)"
              @change:subdomain="(value) => (form.content.SUPPLIER.subdomain = value)"
              :is-disabled="isDisabled"
            />
            <v-qrcode-table
              v-if="form.codeType === 'TABLE'"
              :title="form.title"
              :table="form.content.TABLE.table"
              @change="({ field, value }) => (form[field] = value)"
              @change:table="({ field, value }) => (form.content.TABLE[field] = value)"
              :is-disabled="isDisabled"
            />
            <v-qrcode-room
              v-if="form.codeType === 'ROOM'"
              :title="form.title"
              :room="form.content.ROOM.room"
              @change="({ field, value }) => (form[field] = value)"
              @change:room="({ field, value }) => (form.content.ROOM[field] = value)"
              :is-disabled="isDisabled"
            />
          </v-card-text>
        </v-card>
      </div>
      <div class="qrcode-management-item qrcode-management-item--info">
        <v-qrcode-info-card :qrcode="form" :is-loading="isLoading" />
      </div>
      <div class="qrcode-management-item qrcode-management-item--preview">
        <v-qrcode-preview-card :qrcode="form" :is-loading="isLoading" />
      </div>
      <div class="qrcode-management-item qrcode-management-item--printed">
        <v-qrcode-printed-card :qrcode="form" :is-loading="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import VQRCodeWiFiCard from "./cards/VQRCodeWiFiCard";
import VQRCodeSupplierCard from "./cards/VQRCodeSupplierCard";
import VQRCodeTableCard from "./cards/VQRCodeTableCard";
import VQRCodeRoomCard from "./cards/VQRCodeRoomCard";
import VQRCodeInfoCard from "./cards/VQRCodeInfoCard";
import VQRCodePreviewCard from "./cards/VQRCodePreviewCard";
import VQRCodePrintedCard from "./cards/VQRCodePrintedCard";

import qrcodesService from "../../services/qrcodes.service";

import { toast } from "../../helpers/dialogs";

const qrcodeSchema = () => ({
  supplierId: null,
  subdomain: "",
  title: "",
  description: "",
  codeType: "",
  scanned: 0,
  content: {
    WIFI: {
      ssid: "",
      encryption: "",
      password: "",
    },
    SUPPLIER: {
      subdomain: "",
    },
    TABLE: {
      table: "",
    },
    ROOM: {
      room: "",
    },
  },
  linkId: "",
});

export default {
  name: "VQRCodeManagement",

  mixins: [validationMixin],

  components: {
    "v-qrcode-wifi": VQRCodeWiFiCard,
    "v-qrcode-supplier": VQRCodeSupplierCard,
    "v-qrcode-table": VQRCodeTableCard,
    "v-qrcode-room": VQRCodeRoomCard,
    "v-qrcode-info-card": VQRCodeInfoCard,
    "v-qrcode-preview-card": VQRCodePreviewCard,
    "v-qrcode-printed-card": VQRCodePrintedCard,
  },

  provide() {
    const errors = {};
    Object.defineProperty(errors, "titleErrors", {
      enumerable: true,
      get: () => this.titleErrors,
    });
    return {
      $v: this.$v,
      errors,
    };
  },

  validations() {
    let content = {};
    if (this.form.codeType === "WIFI")
      content = {
        WIFI: { ssid: { required }, encryption: { required }, password: { required } },
      };
    if (this.form.codeType === "TABLE") content = { TABLE: { table: { required } } };
    if (this.form.codeType === "ROOM") content = { ROOM: { room: { required } } };

    return {
      form: {
        title: { required },
        content,
      },
    };
  },

  data() {
    return {
      form: qrcodeSchema(),
      isLoading: false,
      isBusy: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      appSettings: "app/settings",
    }),

    isDisabled() {
      return Boolean(this.form.linkId);
    },

    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      !this.$v.form.title.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  async created() {
    const { qrcodeId } = this.$route.params;

    if (qrcodeId) {
      this.isLoading = true;
      const qrcode = await qrcodesService.getById(qrcodeId);
      this.form = {
        ...this.form,
        ...(qrcode || {}),
      };
      this.isLoading = false;
    } else {
      const { codeType } = this.$route.query;
      this.form.supplierId = this.user.currentSupplier.id;
      this.form.subdomain = this.appSettings.subdomain;
      this.form.codeType = codeType;
    }
  },

  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isBusy = true;

      try {
        if (!this.form.id) {
          const qrcodeResponse = await qrcodesService.create(this.form);
          const { id, linkId } = qrcodeResponse;
          this.form.id = id;
          this.form.linkId = linkId;
        } else await qrcodesService.updateById(this.form.id, this.form);

        await this.$dialog.notify.success(...toast("Changes Saved Successfully"));
        // this.$router.push({ name: "qrcodes" });
      } catch (e) {
        console.error(e);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode-management-header {
  display: flex;
  margin-bottom: 20px;
}

.qrcode-management-grid {
  display: grid;
  grid-template-areas: "general info preview printed";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(465px, auto);
  gap: 20px;
}

.qrcode-management-item {
  display: flex;
  &--general {
    grid-area: general;
  }
  &--info {
    grid-area: info;
  }
  &--preview {
    grid-area: preview;
  }
  &--printed {
    grid-area: printed;
  }
}

.qrcode-general-card {
  width: 100%;
}

@media screen and (max-width: 1600px) {
  .qrcode-management-grid {
    grid-template-areas:
      "general preview"
      "info printed";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(465px, auto) minmax(465px, auto);
  }
}

@media screen and (max-width: 768px) {
  .qrcode-management-grid {
    grid-template-areas:
      "general"
      "preview"
      "info"
      "printed";
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
}
</style>