<template>
  <div class="qrcode-preview">
    <v-card class="qrcode-preview-card" elevation="2" :loading="isLoading">
      <v-toolbar class="qrcode-preview-card__toolbar" flat>
        <v-toolbar-title> {{ $t("GlobalUI.Preview") }} </v-toolbar-title>
        <v-spacer />
        <v-btn color="primary" @click="handleDownloadQrCode" :disabled="!qrcodeText" small>
          {{ $t("GlobalUI.Download") }}
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-qrcode
          v-if="qrcodeText"
          class="qrcode"
          ref="qrcode"
          :text="qrcodeData()"
          @qrcode:click="isQRCodePreview = true"
          is-full
        />
        <div v-else class="qrcode-empty">
          {{ $t("QRCodes.PreviewEmptyText") }}
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      :value="!!isQRCodePreview"
      @click:outside="isQRCodePreview = false"
      max-width="500"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
    >
      <v-card v-if="isQRCodePreview">
        <v-toolbar class="text-uppercase" color="primary" dark>
          <v-spacer />
          <v-btn @click="isQRCodePreview = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-qrcode :text="qrcodeData()" is-full />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VQRCode from "../VQRCode";

import qrcodeData from "../qrcodeData";

export default {
  name: "VQRCodePreviewCard",

  components: { "v-qrcode": VQRCode },

  mixins: [qrcodeData],

  props: ["qrcode", "isLoading"],

  data() {
    return {
      isQRCodePreview: false,
    };
  },

  computed: {
    qrcodeText() {
      return this.qrcodeData();
    },
  },

  methods: {
    handleDownloadQrCode() {
      const image = this.$refs.qrcode.getImage();
      const link = document.createElement("a");
      link.download = `${this.qrcode.codeType}_qrcode.png`;

      link.href = image;
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode-preview {
  width: 100%;
  display: flex;
}
.qrcode-preview-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  .v-card__text {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.qrcode {
  max-width: 368px;
}

.qrcode-links {
  margin-top: 20px;
  display: grid;
  gap: 10px;
}

.qrcode-empty {
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  color: #bdbdbd;
}
</style>