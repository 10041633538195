<template>
  <v-card class="qrcode-info-card" elevation="2" :loading="isLoading">
    <v-toolbar class="qrcode-info-card__toolbar" flat>
      <v-toolbar-title> {{ $t("GlobalUI.Information") }} </v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text>
      <template v-if="['SUPPLIER', 'ROOM', 'TABLE'].includes(qrcode.codeType)">
        <div class="qrcode-info-item qrcode-info-item--link">
          <h3 class="qrcode-info-item__title">
            <span>URL</span>
            <v-icon @click="handleClickCopy($refs.longLink)">mdi-content-copy</v-icon>
          </h3>
          <div class="qrcode-info-item__content">
            <a ref="longLink" :href="longLink" target="_blank">{{ longLink }}</a>
          </div>
        </div>
        <v-divider class="my-4" />
        <div v-if="shortLink" class="qrcode-info-item qrcode-info-item--link">
          <h3 class="qrcode-info-item__title">
            <span>Short URL</span>
            <v-icon @click="handleClickCopy($refs.shortLink)">mdi-content-copy</v-icon>
          </h3>
          <div class="qrcode-info-item__content">
            <a ref="shortLink" :href="shortLink" target="_blank">{{ shortLink }}</a>
          </div>
        </div>
      </template>
      <div
        v-if="['WIFI'].includes(qrcode.codeType)"
        class="qrcode-info-item qrcode-info-item--wifi"
      >
        <h3 class="qrcode-info-item__title">
          <span>{{ $t("QRCodes.ContentOfQrCode") }}</span>
          <v-icon @click="handleClickCopy($refs.wifi)">mdi-content-copy</v-icon>
        </h3>
        <div class="qrcode-info-item__content">
          <span ref="wifi">{{ qrcodeData() }}</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import qrcodeData from "../qrcodeData";

export default {
  name: "VQRCodeInfoCard",

  mixins: [qrcodeData],

  props: ["qrcode", "isLoading"],

  computed: {
    isStaticCode() {
      return (codeType) => ["WIFI"].includes(codeType);
    },
  },

  methods: {
    handleClickCopy(ref) {
      const textArea = document.createElement("textarea");
      textArea.value = ref.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      textArea.remove();

      const range = document.createRange();
      range.selectNodeContents(ref);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      this.$dialog.message.info("Copied", { timeout: 1000 });
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode-info-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  .v-card__text {
    flex-grow: 1;
  }
}

.qrcode-info-item {
  &:last-of-type {
    margin-bottom: 0;
  }
  &--link,
  &--wifi {
    .qrcode-info-item__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}
</style>