<template>
  <div class="qrcode-table">
    <v-text-field
      :value="title"
      @input="$emit('change', { field: 'title', value: $event })"
      :label="$t('GlobalUI.Title')"
      :error-messages="errors.titleErrors"
    />
    <v-text-field
      :value="table"
      @input="$emit('change:table', { field: 'table', value: $event })"
      :label="$t('QRCodes.TableNumber')"
      :error-messages="tableErrors"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VQRCodeTableCard",

  props: ["title", "table", "isDisabled"],

  inject: ["$v", "errors"],

  computed: {
    ...mapGetters({
      appSettings: "app/settings",
    }),

    tableErrors() {
      if (!this.$v.form.content.TABLE) return [];
      const errors = [];
      if (!this.$v.form.content.TABLE.table.$dirty) return errors;
      !this.$v.form.content.TABLE.table.required &&
        errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  created() {
    if (!this.title) this.$emit("change", { field: "title", value: "Table #0" });
    if (!this.table) this.$emit("change:table", { field: "table", value: "0" });
  },
};
</script>