import axios from "axios";

import catchErrors from "../helpers/catchErrors";

import { API_URL } from "../config";

const getAll = catchErrors(async () => {
  const { data, status } = await axios.get(`${API_URL}/qrcodes`);
  return status === 200 && data ? data : [];
});

const getById = catchErrors(async (qrcodeId) => {
  const { data, status } = await axios.get(`${API_URL}/qrcodes/${qrcodeId}`);
  return status === 200 && data ? data : null;
});

const create = catchErrors(
  async (qrcode) => {
    const { data, status } = await axios.post(`${API_URL}/qrcodes`, qrcode);
    return status === 200 && data ? data : null;
  },
  { isThrow: true },
);

const updateById = catchErrors(
  async (qrcodeId, qrcode) => {
    const { status } = await axios.patch(`${API_URL}/qrcodes/${qrcodeId}`, qrcode);
    return status === 200;
  },
  { isThrow: true },
);

const deleteById = catchErrors(async (qrcodeId) => {
  const { status } = await axios.delete(`${API_URL}/qrcodes/${qrcodeId}`);
  return status === 201;
});

export default { getAll, getById, create, updateById, deleteById };
