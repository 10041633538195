<template>
  <div class="qrcode-wifi">
    <v-text-field
      :value="title"
      @input="$emit('change', { field: 'title', value: $event })"
      :label="$t('GlobalUI.Title')"
      :error-messages="errors.titleErrors"
    />
    <v-text-field
      :value="ssid"
      label="Wireless SSID"
      @input="$emit('change:ssid', $event)"
      :error-messages="ssidErrors"
    />
    <v-text-field
      :value="password"
      label="Password"
      @input="$emit('change:password', $event)"
      :error-messages="passwordErrors"
    />
    <v-select
      :value="encryption"
      :items="[
        { value: null, text: 'No encryption' },
        { value: 'WEP', text: 'WEP' },
        { value: 'WPA', text: 'WPA/WPA2' },
      ]"
      label="Encryption"
      @change="$emit('change:encryption', $event)"
      :error-messages="encryptionErrors"
    />
  </div>
</template>

<script>
export default {
  name: "VQRCodeWiFiCard",

  props: ["title", "ssid", "password", "encryption"],

  inject: ["$v", "errors"],

  computed: {
    ssidErrors() {
      const errors = [];
      if (!this.$v.form.content.WIFI.ssid.$dirty) return errors;
      !this.$v.form.content.WIFI.ssid.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.content.WIFI.password.$dirty) return errors;
      !this.$v.form.content.WIFI.password.required &&
        errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
    encryptionErrors() {
      const errors = [];
      if (!this.$v.form.content.WIFI.encryption.$dirty) return errors;
      !this.$v.form.content.WIFI.encryption.required &&
        errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  created() {
    if (!this.title) this.$emit("change", { field: "title", value: "Wi-Fi" });
  },
};
</script>

<style>
</style>