<template>
  <div class="qrcode-supplier">
    <v-text-field
      :value="title"
      @input="$emit('change', { field: 'title', value: $event })"
      :label="$t('GlobalUI.Title')"
      :error-messages="errors.titleErrors"
    />
    <v-text-field :value="subdomain" label="Subdomain" disabled />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VQRCodeSupplierCard",

  props: ["title", "subdomain"],

  inject: ["$v", "errors"],

  computed: mapGetters({
    appSettings: "app/settings",
  }),

  created() {
    if (!this.title) this.$emit("change", { field: "title", value: "Supplier" });
    if (!this.supplierId) this.$emit("change:subdomain", this.appSettings.subdomain);
  },
};
</script>

<style>
</style>