<template>
  <div class="qrcode-room">
    <v-text-field
      :value="title"
      @input="$emit('change', { field: 'title', value: $event })"
      :label="$t('GlobalUI.Title')"
      :error-messages="errors.titleErrors"
    />
    <v-text-field
      :value="room"
      @input="$emit('change:room', { field: 'room', value: $event })"
      :label="$t('QRCodes.RoomNumber')"
      :error-messages="roomErrors"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VQRCodeRoomCard",

  props: ["title", "room", "isDisabled"],

  inject: ["$v", "errors"],

  computed: {
    ...mapGetters({
      appSettings: "app/settings",
    }),

    roomErrors() {
      const errors = [];
      if (!this.$v.form.content.ROOM.room.$dirty) return errors;
      !this.$v.form.content.ROOM.room.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  created() {
    if (!this.title) this.$emit("change", { field: "title", value: "Room #0" });
    if (!this.room) this.$emit("change:room", { field: "room", value: "0" });
  },
};
</script>