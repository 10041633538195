<template>
  <v-card class="qrcode-printed-card" elevation="2" :loading="isLoading">
    <v-toolbar class="qrcode-printed-card__toolbar" flat>
      <v-toolbar-title> Printed production </v-toolbar-title>
      <v-spacer />
      <div :style="{ whiteSpace: 'nowrap' }" class="orange--text">
        {{ $t("GlobalUI.ComingSoon") }}
      </div>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text>
      <v-img max-width="320px" src="../../../assets/qr-code-printed-production-preview.jpg" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "VQRCodePrintedCard",

  props: ["qrcode", "isLoading"],
};
</script>

<style lang="scss" scoped>
.qrcode-printed-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  .v-card__text {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>